<template>
    <div class="page">
        <div class="header page-header" >
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="抽取记录" />
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="项目状态">
                    <el-select v-model="search.status" placeholder="请选择" style="width:100px">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="未开始" value="未开始"></el-option>
                        <el-option label="已抽取" value="已抽取"></el-option>
                        <el-option label="已结束" value="已结束"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目名称">
                    <el-input v-model="search.name" placeholder="请输入名称" clearable />
                </el-form-item>
                <el-form-item label="项目编号">
                    <el-input v-model="search.num" placeholder="请输入项目编号" clearable />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search">查询</el-button>
                    <el-button @click="onExport" icon="el-icon-download" type="defalut">导出</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="small" v-loading="loading" width="100%" height="100%"
                    :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center"
                        :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
                    <el-table-column prop="project.name" label="项目名称" width="" show-overflow-tooltip/>
                    <el-table-column prop="project.code" label="项目编号" width="" show-overflow-tooltip/>
                    <el-table-column prop="project.startTime" label="评审时间" :formatter="dateFormatter" width="150" show-overflow-tooltip/>
                    <el-table-column prop="experts" label="评审专家" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-for="e in scope.row.preferred" style="padding: 0 5px;color:#2081c0">{{ e.name }}</span>
                            <span v-for="e in scope.row.result.random" style="padding: 0 5px">{{ e.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="project.contactName" label="联系人" width="80" />
                    <el-table-column prop="createdAt" label="创建时间" width="220" align="center" show-overflow-tooltip/>
                    <el-table-column align="center" width="120" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="default" size="mini" @click="handleUpdate(scope.row)">
                                查看详情
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            search: { status: '未开始' },
            table: {
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            }
        }
    },
    methods: {
        dateFormatter(v) {
            console.log(v)
            return new Date(v.project.startTime).format('yyyy-MM-dd')
        },
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        onExport() {
            this.$message.info('导出excel下载')
        },
        handleUpdate(row) {
            this.$router.push(`/main/choose/chlog/${row.id}`)
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/expert/choose?page=${this.table.page}&pageSize=${this.table.pageSize}&status=1`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.rows
                this.table.total = resp.data.count
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    padding-bottom: 10px;

    .btns {
        float: right;
    }
}

.search {
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 5px !important;
    }
}

.main-content {
    flex: 1;
    position: relative;
}

.table-content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pager {
    padding-top: 10px;
    text-align: center;
}
</style>
